import Parse, { CloudRunWithCache, CloudRunWithoutCache } from "./config";
import dataExplorer from './data-explorer';
import { log, uid, decompressJsonData, prepareText, tryParseJson, getLocalStorageItem, removeLocalStorageItem } from '../util/algorithm';
import moment from 'moment';
import dataPolicyApi from "./data-policy";
import { windowIdentitySignal } from "../util/signal";

let invalidSessionCount = 0;

const getSystemVersion = async (nocache) => {
  try {
    const param = {};
    const version = await CloudRunWithCache("getSystemVersion", param, nocache);
    if (version) {
      if (!version.homePageUrl) version.homePageUrl = '/';
    }
    invalidSessionCount = 0;
    return version;
  } catch (error) {
    let invalidSession = false;
    if (`${error}`.indexOf("Validation failed. Please login to continue.") !== -1 ||
        `${error}`.indexOf("Invalid session token") !== -1) {
      invalidSession = true;
    }
    if (invalidSession) {
      invalidSessionCount++;
    } else {
      invalidSessionCount = 0;
    }
    if (invalidSessionCount > 20) {
      log('invalid session count > 20', invalidSession);
      log('invalid session', error);
      await Parse.User.logOut();
      removeLocalStorageItem('user_obj');
      window.location.reload(false);
    }
    console.error("list data failed", error);
    throw error;
  }
};

const getActiveDataLastModified = async () => {
  try {
    const param = {};
    const lastModified = await CloudRunWithoutCache("getActiveDataLastModified", param);
    console.log("getActiveDataLastModified()", lastModified)
    return `${lastModified}`;
  } catch (error) {
    console.log("getActiveDataLastModified()", error)
    return uid();
  }
};

const getSharedDataLastModified = async () => {
  try {
    const param = {};
    const lastModified = await CloudRunWithoutCache("getSharedDataLastModified", param);
    console.log("getSharedDataLastModified()", lastModified)
    return lastModified;
  } catch (error) {
    console.log("getSharedDataLastModified()", error)
    return uid();
  }
};

const setupSystem = async (username, nickname, email, password, captcha) => {
  try {
    const param = {username, nickname, email, password, captcha};
    return await CloudRunWithoutCache("setupSystem", param);
  } catch (error) {
    console.error("list data failed", error);
    throw error;
  }
}

const getSchema = async () => {
  try {
    const param = {};
    return await CloudRunWithoutCache("getSchema", param);
  } catch (error) {
    console.error("list data failed", error);
    throw error;
  }
}

const loadSampleData = async () => {
  try {
    const param = {};
    return await CloudRunWithoutCache("loadSampleData", param);
  } catch (error) {
    console.error("list data failed", error);
    throw error;
  }
}

const getEmailAddress = async () => {
  try {
    const param = {};
    return await CloudRunWithoutCache("getEmailAddress", param);
  } catch (error) {
    console.error("list data failed", error);
    throw error;
  }
}

const getEmails = async () => {
  try {
    const param = {};
    return await CloudRunWithoutCache("getEmails", param);
  } catch (error) {
    console.error("list data failed", error);
    throw error;
  }
}

const getSystemEmails = async () => {
  try {
    const param = {};
    return await CloudRunWithoutCache("getSystemEmails", param);
  } catch (error) {
    console.error("list data failed", error);
    throw error;
  }
}

const updateSystemEmails = async (email) => {
  try {
    const param = {email};
    return await CloudRunWithoutCache("updateSystemEmails", param);
  } catch (error) {
    console.error("list data failed", error);
    throw error;
  }
}


const deleteEmail = async (email) => {
  try {
    const param = {email};
    return await CloudRunWithoutCache("deleteEmail", param);
  } catch (error) {
    console.error("list data failed", error);
    throw error;
  }
}

const getSystemParameter = async (name, nocache) => {
  try {
    if (!getLocalStorageItem('user_obj')) {
      log("not login yet, reture null.")
      return null;
    }
    const param = await dataExplorer.getByKey("SystemParameter", name, nocache);
    return param ? param.value : null;
  } catch (error) {
    console.error("get parameter failed", error);
  }
};

const loadMenu = async (locale) => {
  const menu = await getSystemParameter("MENU");
  const nodeMap = tryParseJson(menu);
  if (nodeMap) {
    for (const node of Object.values(nodeMap)) {
      if (node?.props?.dataPolicy) {
        const params = {...node.props}
        try {
          node.props.dataPolicyData = await dataPolicyApi.getDataPolicyData(false, node.props.dataPolicy, "Menu", locale, params)
        } catch (err) {}
      }
    }
    return JSON.stringify(nodeMap);
  } else {
    return null;
  }
}

const saveSystemParameter = async (name, value) => {
  try {
    return await dataExplorer.saveByKey("SystemParameter", name, {name: name, value: value}, false, true);
  } catch (error) {
    console.error("save parameter failed", error);
  }
};

const getAllRoles = async () => {
  try {
    return await dataExplorer.searchAll("Role");
  } catch (error) {
    console.error("get parameter failed", error);
  }
};

const systemExport = async () => {
  try {
    const param = {};
    return await CloudRunWithoutCache("systemExport", param);
  } catch (error) {
    console.error("system export failed", error);
    throw error;
  }
}

const listSystemExport = async () => {
  try {
    const text = await systemExport()
    const list = await listSystemImport(text);
    return {text, list}
  } catch (error) {
    console.error("list data failed", error);
    throw error;
  }
}

const getSystemImportFromServer = async () => {
  try {
     return CloudRunWithoutCache("getSystemImportFromServer");
   } catch (error) {
     console.error("get system import from server failed", error);
      throw error;
   }
}

const listSystemExportWithLabel = async () => {
  try {
    const text = await systemExport()
    const list = await listSystemImportWithLabel(text);
    return {text, list}
  } catch (error) {
    console.error("list data failed", error);
    throw error;
  }
}

const pushToExportMap = (data, key, lineKey, map, includeSource) => {
  if (map) {
    if (!map[lineKey]) {
      map[lineKey] = {};
    }
    if (!map[lineKey][key]) {
      map[lineKey][key] = [];
    }
    map[lineKey][key].push(data);

    if (includeSource) {
      if (key === 'SystemActionPolicy') {
        try {
          map['src/policy/action/history/' + data.actionKey + '.' + data.versionStamp + '.js'] = data.data || '';
          map['src/policy/action/' + data.actionKey + '.js'] = data.data || '';
          if (data.isPublished) {
            map['src/policy/action/' + data.actionKey + '.published.js'] = data.data || '';
          }
        } catch (e) {
          log("try extract source error", e)
        }
      } else if (key === 'SystemDataPolicy') {
        try {
          map['src/policy/data/history/' + data.dataKey + '.' + data.versionStamp + '.js'] = data.data || '';
          map['src/policy/data/' + data.dataKey + '.js'] = data.data || '';
          if (data.isPublished) {
            map['src/policy/data/' + data.dataKey + '.published.js'] = data.data || '';
          }
        } catch (e) {
          log("try extract source error", e)
        }
      } else if (key === 'SystemForm') {
        try {
          const src = JSON.stringify({data: JSON.parse(data.data), extraParams: data.extraParams}, null, 4);
          map['src/form/history/' + data.formKey + '.' + data.versionStamp + '.js'] = src;
          map['src/form/' + data.formKey + '.js'] = src;
          if (data.isPublished) {
            map['src/form/' + data.formKey + '.published.js'] = src;
          }
        } catch (e) {
          log("try extract source error", e)
        }
      } else if (key === 'SystemPage') {
        try {
          const src = JSON.stringify({data: JSON.parse(data.data), extraParams: data.extraParams}, null, 4);
          map['src/page/history/' + data.pageKey + '.' + data.versionStamp + '.js'] = src;
          map['src/page/' + data.pageKey + '.js'] = src;
          if (data.isPublished) {
            map['src/page/' + data.pageKey + '.published.js'] = src;
          }
        } catch (e) {
          log("try extract source error", e)
        }
      }
    }
  }
}

const setToExportMap = (list, key, lineKey, map) => {
  if (map) {
    if (!map[lineKey]) {
      map[lineKey] = {};
    }
    map[lineKey][key] = list;
  }
}

const convertExportMap = (map) => {
  if (map) {
    for (const lineKey of Object.keys(map)) {
      if (!lineKey.startsWith('src'))
        map[lineKey] = JSON.stringify(map[lineKey]);
    }
  }
}

const filterLastVersionExportMap = async (map) => {
  if (map) {
    for (const lineKey of Object.keys(map)) {
      if (lineKey.match(/\/history\//)) {
        delete map[lineKey];
      } else if (lineKey.match(/^src\//)) {
        // ignored
      } else {
        filterLastVersionExportMapImpl(map, lineKey)
      }
    }
  }
}

const filterLastVersionExportMapImpl = (map, lineKey) => {
  try {
    const text = map[lineKey];
    const json = (typeof text === 'string') ? JSON.parse(text) : text;
    for (const k of Object.keys(json)) {
      const arr = json[k];
      if (Array.isArray(arr)) {
        const {last, lastPublished} = filterLastVersionExportMapImplFindVersion(arr);
        filterLastVersionExportMapImplUpdateMap({last, lastPublished, json, k})
      } else {
        log('json is not an array', json)
      }
    }
  } catch (err) {
    log('parse map error', lineKey, err)
  }
}

const filterLastVersionExportMapImplFindVersion = (json) => {
  let last = null;
  let lastPublished = null;
  for (const data of json) {
    if( !last || data.versionStamp > last.versionStamp){
      last = data;
      if (data.isPublished) {
        lastPublished = data;
      }
    }
  }
  return {last, lastPublished}
}

const filterLastVersionExportMapImplUpdateMap = ({last, lastPublished, json, k}) => {
  if (last && lastPublished) {
    if (last.versionStamp === lastPublished.versionStamp) {
      json[k] = [last];
    } else if (last.versionStamp > lastPublished.versionStamp) {
      json[k] = [lastPublished, last];
    } else {
      json[k] = [last, lastPublished];
    }
    log('filtered json', json);
  }
}

const filterSystemExportImpl = async (text, list, map, includeSource) => {
  const dccs = await dataExplorer.getDataClasses();
  const target = JSON.parse(decompressJsonData(text));
  await sortSystemExportByVsersion(target)
  const newTarget = {};
  for (const element of dccs) {
    const dcc = element;
    if (dcc.systemExport && dcc.keyIndex && target[dcc.key] && target[dcc.key].length > 0) {
      const targetList = target[dcc.key];
      for (const element of targetList) {
        const data = element;
        const key = data[dcc.keyIndex];
        const lineKey = `${dcc.key} (${key})`;
        if (list.indexOf(lineKey) !== -1) {
          if (!newTarget[dcc.key]) {
            newTarget[dcc.key] = [];
          }
          const newList = newTarget[dcc.key];
          newList.push(data);
          pushToExportMap(data, dcc.key, lineKey, map, includeSource);
        }
      }
    } else if (dcc.systemExport && dcc.partitionIndex && target[dcc.key] && target[dcc.key].length > 0) {
      const targetList = target[dcc.key];
      for (const element of targetList) {
        const data = element;
        const key = _preparePartitionKey({data, dcc})
        const lineKey = `${dcc.key} (${key})`;
        if (list.indexOf(lineKey) !== -1) {
          if (!newTarget[dcc.key]) {
            newTarget[dcc.key] = [];
          }
          const newList = newTarget[dcc.key];
          newList.push(data);
          pushToExportMap(data, dcc.key, lineKey, map, includeSource);
        }
      }
    } else if (dcc.systemExport && !dcc.keyIndex && target[dcc.key] && target[dcc.key].length > 0) {
      const targetList = target[dcc.key];
      const key = 'All';
      const lineKey = `${dcc.key} (${key})`;
      if (list.indexOf(lineKey) !== -1) {
        newTarget[dcc.key] = targetList;
        setToExportMap(targetList, dcc.key, lineKey, map);
      }
    }
  }
  return JSON.stringify(newTarget);
}

const filterSystemExport = async (text, list, importLastVersionOnly, map, includeSource) => {
  try {
    let dataObj = await filterSystemExportImpl(text, list, map, includeSource);
    if(dataObj && importLastVersionOnly){
      dataObj = await filterSystemExportByLastVsersion(dataObj);
      await filterLastVersionExportMap(map);
    }
    convertExportMap(map);
    return dataObj;
  } catch (error) {
    console.error("system export failed", error);
    throw error;
  }
}

const listSystemImport = async (text) => {
  const dccs = await dataExplorer.getDefaultDataClasses();
  const target = JSON.parse(decompressJsonData(text));
  const list = [];
  for (const element of dccs) {
    const dcc = element;
    if (dcc.systemExport && dcc.keyIndex && target[dcc.key] && target[dcc.key].length > 0) {
      const targetList = target[dcc.key];
      const keySet = {};

      for (const element of targetList) {
        const data = element;
        const key = data[dcc.keyIndex];
        if (!keySet[key]) {
          list.push(`${dcc.key} (${key})`);
          keySet[key] = true;
        }
      }
    } else if (dcc.systemExport && dcc.partitionIndex && target[dcc.key] && target[dcc.key].length > 0) {
      const targetList = target[dcc.key];
      const keySet = {};

      for (const element of targetList) {
        const data = element;
        const key = _preparePartitionKey({data, dcc})
        if (!keySet[key]) {
          list.push(`${dcc.key} (${key})`);
          keySet[key] = true;
        }
      }
    } else if (dcc.systemExport && !dcc.keyIndex && target[dcc.key] && target[dcc.key].length > 0) {
      const key = "All";
      list.push(`${dcc.key} (${key})`);
    }
  }
  return list;
}

const getCurrentKeyUpdateDate = (list,key) => {
  let lastUpdateDate = new Date(1999-1-1)
  if(list){
    list.sort(function (a, b) {
      return (a.updatedAt > b.updatedAt)
    }).reverse()
    lastUpdateDate = list[0].updatedAt
  }
  return lastUpdateDate
}

const _preparePartitionKey = ({data, dcc}) => {
  let key = null;
  for (const keyIndex of dcc.partitionIndex) {
    const k = data[keyIndex];
    let l = null;
    if (typeof k === 'boolean') {
      l = k === true ? keyIndex : '';
    } else if (k) {
      l = `${k}`;
    } else {
      l = ``
    }
    if (l && l.length > 0) {
      key = key ? `${key} ${l}` : l;
    }
  }
  if (!key || key.length === 0) key = `Others`;
  return key;
}

const listSystemImportWithLabel = async (text) => {
  const dccs = await dataExplorer.getDataClasses();
  const target = JSON.parse(decompressJsonData(text));
  const list = [];
  for (const element of dccs) {
    const dcc = element;
    if (dcc.systemExport && dcc.keyIndex && target[dcc.key] && target[dcc.key].length > 0) {
      const targetList = target[dcc.key];
      const keySet = {};
      if (targetList) {
        targetList.sort((a, b) => {
          return moment(b.updatedAt).diff(moment(a.updatedAt))
        })
      }

      for (const element of targetList) {
        const data = element;
        const key = data[dcc.keyIndex];
        const labelPattern = dcc.labelPattern;
        const label = labelPattern ? prepareText(labelPattern, data) : null;
        if (label && label !== key) {
          if (!keySet[key]) {
            list.push({value:  `${dcc.key} (${key})`, label: `${dcc.key} (${label} - ${key})`, updatedate: data.updatedAt});
            keySet[key] = true;
          }
        } else if (!keySet[key]) {
          list.push({value: `${dcc.key} (${key})`, label: `${dcc.key} (${key})`, updatedate: data.updatedAt});
          keySet[key] = true;
        }
      }
    } else if (dcc.systemExport && dcc.partitionIndex && target[dcc.key] && target[dcc.key].length > 0) {
      const targetList = target[dcc.key];
      const keySet = {};
      if (targetList) {
        targetList.sort((a, b) => {
          return moment(b.updatedAt).diff(moment(a.updatedAt))
        })
      }

      for (const element of targetList) {
        const data = element;
        const key = _preparePartitionKey({data, dcc})
        if (!keySet[key]) {
          list.push({value: `${dcc.key} (${key})`, label: `${dcc.key} (${key})`, updatedate: data.updatedAt});
          keySet[key] = true;
        }
      }
    } else if (dcc.systemExport && !dcc.keyIndex && target[dcc.key] && target[dcc.key].length > 0) {
      const lastUpdateDate = getCurrentKeyUpdateDate(target[dcc.key],dcc.key)
      const key = "All";
      list.push({value: `${dcc.key} (${key})`, label: `${dcc.key} (${key})`, updatedate: lastUpdateDate});
    }
  }
  return list;
}

const systemImport = async (text, list, importLastVersionOnly) => {
  try {
    const param = {text, list, importLastVersionOnly};
    return await CloudRunWithoutCache("systemImport", param);
  } catch (error) {
    console.error("system import failed", error);
    throw error;
  }
}

const systemImportFromServer = async (importFileName, importLastVersionOnly) => {
  try {
    const param = {importFileName, importLastVersionOnly};
    return await CloudRunWithoutCache("systemImportFromServer", param);
  } catch (error) {
    console.error("system import failed", error);
    throw error;
  }
}

const getSystemLogging = async (last) => {
  try {
    const param = {last};
    return await CloudRunWithoutCache("getSystemLogging", param);
  } catch (error) {
    console.error("system import failed", error);
    throw error;
  }
}

const clearSystemLogging = async () => {
  try {
    const param = {};
    return await CloudRunWithoutCache("clearSystemLogging", param);
  } catch (error) {
    console.error("system import failed", error);
    throw error;
  }
}

const isClientLogging = async () => {
  try {
    const param = {};
    return await CloudRunWithCache("isClientLogging", param, true);
  } catch (error) {
    console.error("check client logging failed", error);
    return false;
  }
}

const clientLogging = async (message) => {
  try {
    const client = window?.navigator?.userAgent;
    const param = {message, client};
    return await CloudRunWithoutCache("clientLogging", param);
  } catch (error) {
    console.error("client logging failed", error);
    throw error;
  }
}

const generateAccessKey = async () => {
  try {
    const param = {};
    const accessKey = await CloudRunWithoutCache("generateAccessKey", param);
    log("accessKey", accessKey);
    return accessKey;
  } catch (error) {
    console.error("get access key failed", error);
    throw error;
  }
}

const SUBSCRIPTIONS = {
  subscription: null,
  listeners: {}
};

const getCurrentUser = async () => {
  try {
    const currentUser = await Parse.User.current();
    return currentUser;
  } catch (error) {
    return null;
  }
}

const getCurrentSession = async () => {
  try {
    const currentSession = await Parse.Session.current();
    return currentSession;
  } catch (error) {
    return null;
  }
}

const startNotification = async (userId, handler) => {
  const DataClass = 'UserNotification';
  const oldSubscription = SUBSCRIPTIONS.subscription;
  if (oldSubscription) {
    try {
      oldSubscription.unsubscribe();
      SUBSCRIPTIONS.listeners = {};
    } catch (error) {
      log("close connection error", error);
    }
  }

  const query = new Parse.Query(DataClass);
  let currentUser = null;
  try {
    currentUser = await Parse.User.current();
    const installationId = await Parse._getInstallationId()
    windowIdentitySignal.installationId = installationId;
    console.log("startNotification: currentUser", currentUser);
    console.log("startNotification: installationId", installationId);
    query.equalTo("user", currentUser);
    query.equalTo("installationId", installationId);
    const subscription = await query.subscribe();
    SUBSCRIPTIONS.subscription = subscription;
    subscription.on('open', (...args) => {
      console.log('subscription opened', args);
    });
    subscription.on('create', (object) => {
      const json = object.toJSON()
      console.log('object created', json);
      const subject = object.get("subject");
      const id = object.id;
      const params = json.params;
      trigger({subject, id, params})
    });
  } catch (e) {
    console.log("startNotification error", e)
  }
}

const trigger = async ({subject, id, params}) => {
  const map = SUBSCRIPTIONS.listeners[subject];
  if (map) {
    Object.values(map).forEach(h => {
      if (h) {
        try {
          h(id, params);
        } catch (e) {
          console.log('failed to execute event handler', e)
        }
      }
    })
  }
}

const subscribe = async (subject, handle) => {
  try {
    const param = {subject};
    await CloudRunWithoutCache("notification_subscribe", param);
    let map = SUBSCRIPTIONS.listeners[subject];
    if (!map) {
      map = {};
      SUBSCRIPTIONS.listeners[subject] = map;
    }
    const key = uid();
    map[key] = handle;
    return key;
  } catch (error) {
    // ignore this error
  }
}

const unsubscribe = async (subject, key) => {
  try {
    const param = {subject};
    await CloudRunWithoutCache("notification_unsubscribe", param);
    let map = SUBSCRIPTIONS.listeners[subject];
    if (!map) {
      map = {};
      SUBSCRIPTIONS.listeners[subject] = map;
    }
    map[key] = undefined;
  } catch (error) {
    console.error("system unsubcribe failed", error);
  }
}

const notify = async (subject, params) => {
  try {
    const param = {subject, params};
    await CloudRunWithoutCache("notification_notify", param);
  } catch (error) {
    console.error("system import failed", error);
  }
}

const apiNotify = async (subject, isJob, params) => {
  try {
    const param = {subject, isJob, params};
    await CloudRunWithoutCache("notification_notify", param);
  } catch (error) {
    console.error("system import failed", error);
  }
}

const received = async (notifyId) => {
  try {
    const param = {notifyId};
    await CloudRunWithoutCache("notification_received", param);
  } catch (error) {
    console.error("system import failed", error);
  }
}

const compareAndAssignVersion = (lastVersions, data, key) => {
  if (lastVersions[key]) {
    if (data.versionStamp > lastVersions[key]) {
      lastVersions[key] = data.versionStamp;
    }
  } else {
    lastVersions[key] = data.versionStamp;
  }
}

const isMatchedVersion = (lastVersions, data, keys) => {
  let isMatched = false;
  keys.forEach(k => {
    if (lastVersions[k] && lastVersions[k] === data.versionStamp) {
      isMatched = true;
    }
  })
  return isMatched;
}

const sortSystemExportByVsersion =async (target) => {
  const versionStamp = "versionStamp";
  const dccs = await dataExplorer.getDataClasses();
  for (const dcc of dccs) {
    const targetList = target[dcc.key];
    if (targetList && Array.isArray(targetList)) {
      targetList.sort((a, b) => a?.[versionStamp] - b?.[versionStamp])
    }
  }
}

const filterSystemExportByLastVsersion =async (exportData) => {
  const versionStamp = "versionStamp";
  const dccs = await dataExplorer.getDataClasses();
  const newTarget = {};
  const target = JSON.parse(exportData);
  for (const dcc of dccs) {
    const newElementData = [];
    if (dcc.systemExport && dcc.keyIndex && target[dcc.key] && target[dcc.key].length > 0) {
      const targetList = target[dcc.key];
      const targetListstr = JSON.stringify(targetList)
      const keySet = {};
      const versionSet = {};
      const lineSet = {};
      let haveVersion = true;
      for (const data of targetList) {
        haveVersion = !!data[versionStamp]
        const key = data[dcc.keyIndex];
        const lineKey =`"${dcc.keyIndex}":"${key}"`;
        if (!keySet[key]) {
          if (targetListstr.indexOf(lineKey) !== -1) {
            lineSet[lineKey] = true;
            keySet[key] = true;
            versionSet[key] = {"lastVersion":null, "lastPublishedVersion":null};
          }
        }
      }
      if (haveVersion) {
        for (const element of targetList) {
          const data = element;
          const key = data[dcc.keyIndex];
          const lineKey = `"${dcc.keyIndex}":"${key}"`;
          if (keySet[key] && lineSet[lineKey]) {
            const lastVersions = versionSet[key];
            compareAndAssignVersion(lastVersions, data, "lastVersion");
            if (data.isPublished) {
              compareAndAssignVersion(lastVersions, data, "lastPublishedVersion");
            }
          }
        }
        for (const element of targetList) {
          const data = element;
          const key = data[dcc.keyIndex];
          const lineKey = `"${dcc.keyIndex}":"${key}"`;
          if (keySet[key] && lineSet[lineKey]) {
            const lastVersions = versionSet[key];
            if (isMatchedVersion(lastVersions, data, ["lastVersion", "lastPublishedVersion"])) {
              newElementData.push(data);
            }
          }
        }
      }else{
        for (const element of targetList) {
          const data = element;
          newElementData.push(data);
        }
      }
      newTarget[dcc.key] = newElementData;
    }else if(target[dcc.key]){
      newTarget[dcc.key] = target[dcc.key];
    }
  }
  return JSON.stringify(newTarget) ;
}

const triggerResetPasswordFlow = async (values) => {
  try {
    const param = values;
    return await CloudRunWithoutCache("triggerResetPasswordFlow", param);
  } catch (error) {
    console.error("initialiate reset password failed", error);
    throw error;
  }
}

const preVerifyEmailToken = async (values) => {
  try {
    const param = values;
    return await CloudRunWithoutCache("preVerifyEmailToken", param);
  } catch (error) {
    console.error("pre-verify email token failed", error);
    throw error;
  }
}

const verifyEmailToken = async (values) => {
  try {
    const param = values;
    return await CloudRunWithoutCache("verifyEmailToken", param);
  } catch (error) {
    console.error("verify email token failed", error);
    throw error;
  }
}

const resetPassword = async (values) => {
  try {
    const param = values;
    return await CloudRunWithoutCache("resetPassword", param);
  } catch (error) {
    console.error("reset password failed", error);
    throw error;
  }
}

const system = {
  getSystemVersion,
  getActiveDataLastModified,
  getSharedDataLastModified,
  setupSystem,
  getSchema,
  systemExport,
  listSystemExport,
  listSystemExportWithLabel,
  listSystemImportWithLabel,
  filterSystemExport,
  systemImport,
  getSystemLogging,
  clearSystemLogging,
  isClientLogging,
  clientLogging,
  listSystemImport,
  getSystemImportFromServer,
  systemImportFromServer,
  loadSampleData,
  getSystemParameter,
  loadMenu,
  saveSystemParameter,
  getAllRoles,
  getEmails,
  getSystemEmails,
  updateSystemEmails,
  deleteEmail,
  getEmailAddress,
  generateAccessKey,
  startNotification,
  subscribe,
  unsubscribe,
  trigger,
  notify,
  apiNotify,
  received,
  getCurrentUser,
  getCurrentSession,
  triggerResetPasswordFlow,
  preVerifyEmailToken,
  verifyEmailToken,
  resetPassword
}

export default system;
import { useEditor, useNode } from "@craftjs/core";
import { Col, InputNumber, Skeleton, Switch } from "antd";
import { Form, Input } from "components/Form";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { BsFileEarmarkRichtext } from "react-icons/bs";
import IntlMessages from "util/IntlMessages";
import { equals, isEmpty } from "util/algorithm";
import CdnCKEditor from "../../../../components/CdnCKEditor";
import { fontBackgroundColor, fontColor } from "./SfTextEditorConfig";
import {
  EditorCollector,
  NodeCollector,
  SfLayoutSetting, SfPanelContext,
  colSls, convertRules, convertStyleStr, deleteButton,
  getId,
  registerComponent,
  shouldUpdate
} from "./common";

export { CKSTYLE } from "./SfTextEditorConfig";

const SfTextEditorSetting = () => {
  return (
    <>
      <Form.Item name="title" label="title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="translate" label="translate" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="readOnly" label="read-only" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="advanced" label="advanced" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="imageBase64" label="image" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="highlight" label="highlight" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="fontstyle" label="font style" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="codestyle" label="code style" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="wraptoolbar" label="wrap toolbar" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="hideInTable" label="hide col." valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="hideInMobile" label="hide mob." valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="tableColWidth" label="col width">
        <InputNumber min="1" className="item-property" />
      </Form.Item>
      <Form.Item name="tableColTitle" label="col title">
        <Input className="item-property" />
      </Form.Item>
      <SfLayoutSetting />
    </>
  )
}

export const SfTextEditor = ({ ...props }) => {
  const deletedRef = useRef();
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions, deletedRef)
  return (
    <SfpTextEditor doRef={ref => connect(drag(ref))} style={style} dbtn={dbtn} {...props} />
  )
}

const setupToolbar = ({imageBase64, highlight, fontstyle, codestyle, advanced, wraptoolbar, sourceEditing}) => {
  return {
    items: [
      'heading',
      '|',
      'bold',
      'italic',
      ...(advanced ? ['horizontalLine'] : []),
      'bulletedList',
      'numberedList',
      '|',
      'outdent',
      'indent',
      ...(advanced ? ['alignment'] : []),
      '|',
      ...(highlight ? ['highlight'] : []),
      'blockQuote',
      ...(codestyle ? ['code', 'codeBlock'] : []),
      'insertTable',
      'link',
      ...(imageBase64 ? ['imageUpload'] : []),
      'mediaEmbed',
      ...(advanced ? ['findAndReplace'] : []),
      'undo',
      'redo',
      ...(fontstyle ? ['-',
      'fontFamily',
      'fontColor',
      'fontBackgroundColor',
      'fontSize'] : []),
      ...(sourceEditing ? ['sourceEditing'] : []),
    ],
    shouldNotGroupWhenFull: !!wraptoolbar
  };
}

export const CK_PATCH = /<(\/?)figure([^>]*)>/g;
export const CK_PATCH_REPLACE = '<$1div$2>';
export const patchCkHtml = (html) => {
  return html?.replace(CK_PATCH, CK_PATCH_REPLACE);
}


export const MyCKEditor = ({
  id, noupdate, editorRef, value, onChange, readOnly, disabled, staticContent,
  imageBase64, highlight, fontstyle, codestyle, advanced, wraptoolbar, designer,
  sourceEditing,
}) => {
  const [editorState,setEditorState] = useState("<p></p>");
  const [domEditor,setDomEditor] = useState();
  const [toolbar,setToolbar] = useState(setupToolbar({imageBase64, highlight, fontstyle, codestyle, advanced, wraptoolbar}));
  const [loading, setLoading] = useState(false);
  const mounted = useRef();
  const ref = useRef();
  const tgr = useRef();
  if (ref.current === undefined) ref.current = noupdate && value !== undefined;

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    }
  },[]);

  useEffect(() => {
    if (!isEmpty(staticContent)){
      if (mounted.current) {
        if (!equals(editorState, staticContent)) {
          setEditorState(staticContent);
        }
      }
    } else if (!isEmpty(value)) {
      if (mounted.current) {
        if (!equals(editorState, value)) {
          const text = !isEmpty(value) ? value : "<p></p>";
          setEditorState(text);
        }
      }
    } else {
      if (mounted.current) {
        setEditorState("<p></p>");
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, staticContent]);

  useEffect(() => {
    setLoading(true);
    setToolbar(setupToolbar({imageBase64, highlight, fontstyle, codestyle, advanced, wraptoolbar, sourceEditing}));
    setTimeout(()=>setLoading(false), 500);
  },[imageBase64, highlight, fontstyle, codestyle, advanced, wraptoolbar, sourceEditing])

  useEffect(() => {
    if (editorRef) {
      editorRef.current = {
        focus: () => {
          if (domEditor) {
            domEditor.focus();
          }
        }
      }
    }
    if (value) {
      if (domEditor) domEditor.setData(value);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domEditor])

  const onMyChange = useCallback((event, editor) => {
    const data = editor.getData();
    setEditorState(data);
    if (tgr.current) {
      clearTimeout(tgr.current);
    }
    tgr.current = setTimeout(() => {
      if (onChange) {
        onChange(data);
      }
    }, 500);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onBlur = useCallback((event, editor) => {
    const data = editor.getData();
    if (onChange) onChange(data);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) {
    return (<Skeleton active />)
  } else if (disabled || readOnly || ref.current) {
    return (<div className="ck-content" dangerouslySetInnerHTML={{__html: editorState}} />)
  } else {
    return (
      <div >
        <CdnCKEditor
          id={id}
          data={editorState}
          onReady={setDomEditor}
          onChange={onMyChange}
          onBlur={onBlur}
          config={{
            fontColor: fontColor,
            fontBackgroundColor: fontBackgroundColor,
            toolbar: toolbar,
          }}
        />
      </div>
    )
  }
}

export const SfpTextEditor = ({
  doRef, form, condistyles, className, style, dbtn, hideInTable, tableColWidth, tableColTitle,
  itemKey, title, labelAlign, labelColStr, rules, translate, volitate, skipcopy,
  inputref, styleStr, children, ...otherProps }) => {
  const sls = convertStyleStr(styleStr);
  const lcs = convertStyleStr(labelColStr);
  const [fxr, setFxr] = useState({});
  const newRules = convertRules(rules);
  return (
    <SfPanelContext.Consumer>
      {ctx => {
        const name = ctx ? [...ctx.name, itemKey] : [itemKey];
        const fx = shouldUpdate({condistyles, ctx, form, style, setFxr});
        return (
          <Col ref={doRef} className={className} style={fxr.style || style} {...colSls(otherProps)}>
            <Form.Item name={name} label={title}
              shouldUpdate={fx} hidden={fxr.hidden} rules={fxr.hidden || otherProps.disabled ? null : newRules}
              labelAlign={labelAlign} labelCol={lcs}
              getValueFromEvent={(value)=>value} wrap>
              <MyCKEditor editorStyle={sls} {...otherProps} designer={!!doRef}/>
            </Form.Item>
            {dbtn}
          </Col>
        )
      }}
    </SfPanelContext.Consumer>
  );
}

const HtmlRenderer = ({value, record, index, form, tableKey, onRowChange, editInTable, itemKey, tableColWidth, disabled}) => {
  return (<div className="ck-content" dangerouslySetInnerHTML={{__html: value}} />)
}

SfpTextEditor.render = ({ systemFormatter, editInTable, itemKey, tableColWidth, disabled }, form, tableKey, onRowChange) => (value, record, index) => {
  const props = {value, record, index, form, tableKey, onRowChange, editInTable, itemKey, tableColWidth, disabled};
  return <HtmlRenderer {...props}/>
}

SfTextEditor.craft = {
  displayName: "Text Editor",
  related: {
    settings: SfTextEditorSetting
  }
}

SfTextEditor.validate = (props, {parents, container, extraParams}) => {
  const itemKey = props.itemKey;
  const title = props.title;
  if (container.type.resolvedName !== "SfMainPanel") {
    if (props.translate) {
      return <IntlMessages id="system.form.validate.non-main-panel-translate" text="item ({itemKey}) - input translate is only allowed in main panel." values={{itemKey:itemKey}}/>
    }
  }
  if (container.type.resolvedName === "SfMainPanel") {
    extraParams.dataClassConfig.columns.push({
      dataIndex: props.itemKey,
      index: Object.keys(parents).indexOf(props.itemKey),
      type: "string",
      typeRender: "htmltext",
      title: props.tableColTitle || props.title,
      width: props.tableColWidth,
      sortable: true,
      editable: false,
      permission: props.permission,
      volitate: props.volitate,
      hiddenForReadOnly: props.hideInTable,
      translate: props.translate,
    });
    if (props.translate) {
      extraParams.translate.enabled = true;
      extraParams.translate.items.push({
        key:itemKey,
        type: 'texteditor',
        label: title,
        index: Object.keys(parents).indexOf(props.itemKey)
      })
    }
  }
}

SfTextEditor.isSearchable = true;
SfTextEditor.isRulable = true;

registerComponent({
  key: "SfTextEditor",
  component: SfTextEditor,
  runtimeComponent: SfpTextEditor,
  template: <SfTextEditor itemKey={getId('texteditor')} className="sf-texteditor wrap"
    title={"Text Editor"} span={24} labelColStr="span:6" hideInTable={true} htmlInTable={true}/>,
  title: <IntlMessages id="system.form.library.texteditor" text="Text Editor" />,
  icon: <BsFileEarmarkRichtext  className="react-icons icon-bs"/>,
  type: "Component",
  sequence: 14,
});

export default SfTextEditor;
import { AccountStore } from 'constants/Account';
import IntlMessages from "util/IntlMessages";
import { getFileExtension, isEmpty, log, parseDate } from '../util/algorithm';
import Parse, { saveData } from './config';
import { Queue } from '../util/singleton';

const UPLOAD_SIZE_PATTERN = /^(\d+)mb$/;
export const checkFileSize = (file) => {
  let max = 10;
  if (AccountStore.MAX_UPLOAD_SIZE && AccountStore.MAX_UPLOAD_SIZE.match(UPLOAD_SIZE_PATTERN)) {
    max = parseInt(AccountStore.MAX_UPLOAD_SIZE.replace(UPLOAD_SIZE_PATTERN, '$1'), 10);
  }
  const isLt10M = file.size / 1024 / 1024 <= max;
  return isLt10M;
}

export const checkFileAcceptedTypes = (file, acceptFileTypes) => {
  if (!acceptFileTypes || acceptFileTypes.length === 0) {
    return true;
  }
  if (acceptFileTypes.includes(file.type)) {
    return true;
  }
  for (const fileType of acceptFileTypes) {
    if (file.type && file.type.match(new RegExp(fileType, 'i'))) {
      return true;
    }
  }
  return false;
}

export const checkFileAcceptedExtensions = (file, acceptFileExtensions) => {
  if (!acceptFileExtensions || acceptFileExtensions.length === 0) {
    return true;
  }
  if (acceptFileExtensions.includes(file.type)) {
    return true;
  }
  if (file.name) {
    const fext = getFileExtension(file.name);
    for (const fileExtension of acceptFileExtensions) {
      if (fext && fext.match(new RegExp(fileExtension, 'i'))) {
        return true;
      }
    }
  }
  return false;
}

export const addFileUploaded = async (file, parseFile) => {
  log("addFileUploaded", file, parseFile);
  let currentUser = null;
  try {
    currentUser = await Parse.User.current();
  } catch (e) {}
  const uploadFile = {
    name: file.name,
    fileKey: parseFile.name(),
    url: parseFile.url(),
    size: file.size,
    type: file.type,
    status: file.status,
    percent: file.percent,
    lastModifiedDate: parseDate(file.lastModifiedDate),
    thumbUrl: file.thumbUrl,
    uploadedDate: parseDate(new Date()),
    file: parseFile,
    uploadedBy: currentUser,
    uploadUrl: window.location.href,
    permission: file.permission,
  };
  if (!uploadFile.fileKey) {
    log("fail to save file as missing fileKey", uploadFile);
  } else {
    await saveData("SystemFile", "fileKey", uploadFile.fileKey, uploadFile);
  }
}

export const blobToData = (blob) => {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}

export const patchUploadFileName = (name) => {
  let newName = name;
  if (newName) {
    newName = encodeURIComponent(newName.replace(/[^A-Za-z0-9.]/g,'_'));
    newName = newName.toLowerCase();
    const parts = newName.split(".");
    if (parts.length > 1) {
      const replacedParts = [parts.slice(0, -1).join('_'), parts.slice(-1)];
      newName = replacedParts.join(".");
    }
  }
  if (isEmpty(newName)) newName = "_NO_NAME_.dat"
  return newName;
}

const fileUploadQueue = new Queue("fileUploadQueue");

fileUploadQueue.process(async (task) => {
  log("fileUploadQueue.process", task);
  const { name, file, progress } = task.data;
  let parseFile = task.data.parseFile;
  let finished = false;
  try {
    log("handleUploadFile parseFile", parseFile);
    parseFile = await parseFile.save({
      progress: (progressValue, loaded, total, {type}) => {
        log("handleUploadFile progress", name, progressValue, loaded, total, type);
        if (!finished) progress(file, progressValue, {loaded, total, type}, null);
      }
    });
    finished = true;
    await addFileUploaded(file, parseFile);
    log("handleUploadFile finished", file, parseFile);
    progress(file, 1, null, parseFile);
  } catch (e) {
    log("handleUploadFile", e);
    progress(file, 1, null, null, e);
  }
})

export const handleUploadFile = async (name, file, progress, acceptFileTypes, acceptFileExtensions) => {
  log("handleUploadFile", name, file);
  const newName = patchUploadFileName(name);
  log("handleUploadFile file name", name, newName);
  if (!checkFileSize(file.originFileObj ? file.originFileObj : file)) {
    progress(file, 1, null, null, <IntlMessages id="system.form.upload_size_limit" text="File too large ({max})." values={{max: AccountStore.MAX_UPLOAD_SIZE}}/>);
    return;
  }
  if (!checkFileAcceptedTypes(file, acceptFileTypes)) {
    progress(file, 1, null, null, <IntlMessages id="system.form.upload_file_not_accepted" text="Invalid file type." />);
    return;
  }
  if (!checkFileAcceptedExtensions(file, acceptFileExtensions)) {
    progress(file, 1, null, null, <IntlMessages id="system.form.upload_file_not_accepted" text="Invalid file type." />);
    return;
  }
  let parseFile = new Parse.File(newName, file.originFileObj ? file.originFileObj : file);
  const uniqueIdentifier = file.uid;
  fileUploadQueue.add({name, file, progress, parseFile, uniqueIdentifier})
}

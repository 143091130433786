import Keycloak from "keycloak-js";
import { auth } from ".";
import { AccountStore } from "../constants/Account";
import { decrypt, delay, encodeBase64, encrypt, getBaseUrl, getLocalStorageItem, getLocalStorageItemObject, log, removeLocalStorageItem, setLocalStorageItem, setLocalStorageItemObject, toAuthUserObj, urlparam } from "../util/algorithm";
import { authSignal, keycloakInitializedSignal } from "../util/signal";

const DEFAULT_KEYCLOAK_CHECK_INTERVAL = 60;
const DEFAULT_KEYCLOAK_CHECK_INITIAL_DELAY = 5 * 1000;
const DEFAULT_SAML_CHECK_INTERVAL = 1800;


const isPasswordEnabled = () => {
  const enabled = !!AccountStore.LOGIN_WITH_PASSWORD
  log('keycloaksso: isPasswordEnabled()', enabled)
  return enabled;
}

const isKeycloakEnabled = () => {
  const enabled = !!AccountStore.KEYCLOAK_REALM && !!AccountStore.KEYCLOAK_URL && !!AccountStore.KEYCLOAK_RESOURCE;
  log('keycloaksso: isKeycloakEnabled()', enabled)
  return enabled;
};


const isKeycloakOnly = () => {
  const only = !isPasswordEnabled() && isKeycloakEnabled() && !isSamlEnabled();
  log('keycloaksso: isKeycloakOnly()', only);
  return only;
};

const getKeycloakInfo = () => {
  const info = {
    url: AccountStore.KEYCLOAK_URL,
    realm: AccountStore.KEYCLOAK_REALM,
    clientId: AccountStore.KEYCLOAK_RESOURCE,
  };
  return info;
};

const getKeycloakUrlParams = () => {
  const info = getKeycloakInfo();
  const urlParams = {
    keycloak_url: info.url,
    keycloak_realm: info.realm,
    keycloak_resource: info.clientId,
  };
  return urlParams;
};

const isKeycloakCallback = () => {
  let isCallback = false;
  const hash = window.location.hash;
  if (hash && hash.indexOf("state=") !== -1) {
    isCallback = true;
  } else {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key.startsWith("kc-callback-")) {
        isCallback = true;
      }
    }
  }
  log('keycloaksso: isKeycloakCallback()', isCallback);
  return isCallback;
};

const clearKeycloakCallback = () => {
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key.startsWith("kc-callback-")) {
      localStorage.removeItem(key);
    }
  }
};

const doLoadSession = (session) => {
  if (session) {
    let url = window.location.href;
    url = url.replace(/\?session=.*$/,'');
    window.location.href = url + "?session=" + session
  }
}
class KeycloakApi {
  constructor() {
    this.keycloak = null;

    this.clearKeycloakCallback = clearKeycloakCallback.bind(this);
    this.isKeycloakCallback = isKeycloakCallback.bind(this);
    this.getKeycloakInfo = getKeycloakInfo.bind(this);
    this.getKeycloakUrlParams = getKeycloakUrlParams.bind(this);
    this.isKeycloakOnly = isKeycloakOnly.bind(this);
    this.isKeycloakEnabled = isKeycloakEnabled.bind(this);
    this.initializeKeycloak = this.initializeKeycloak.bind(this);
    this.getKeycloak = this.getKeycloak.bind(this);
  }

  initializeKeycloak() {
    if (this.isKeycloakEnabled()) {
      if (!this.keycloak) {
        this.keycloak = new Keycloak({
          url: AccountStore.KEYCLOAK_URL,
          realm: AccountStore.KEYCLOAK_REALM,
          clientId: AccountStore.KEYCLOAK_RESOURCE,
        })
      }
    }
  };

  patchRedirectUri(redirectUri) {
    const invalidCharacters = /[% ]/g;
    if (redirectUri && invalidCharacters.exec(redirectUri)) {
      const PAGE_URL_PATTERN = new RegExp('^.*?/(system|page)/prt/(preview|fm|pg)/?([^/]+)/?(.*)$');
      if (PAGE_URL_PATTERN.exec(redirectUri)) {
        const moreAttributes = redirectUri.replace(PAGE_URL_PATTERN, "$4");
        if (moreAttributes) {
          const base64Encoded = encodeBase64(moreAttributes);
          redirectUri = redirectUri.replace(moreAttributes, base64Encoded);
        }
      }
    }
    return redirectUri;
  }

  getKeycloak(noLogin) {
    log('keycloaksso: getKeycloak() noLogin', noLogin)
    if (!this.isKeycloakEnabled()) {
      return Promise.resolve(null);
    } else {
      this.initializeKeycloak();
      return new Promise(async (resolve, reject) => {
        const baseUrl = getBaseUrl();
        if (keycloakInitializedSignal.value) {
          resolve(this.keycloak);
        } else if (keycloakInitializedSignal.prcessing) {
          await keycloakInitializedSignal.waitFor('value', true);
          resolve(this.keycloak);
        } else {
          keycloakInitializedSignal.prcessing = true;
          this.keycloak
            .init({
              onLoad: "check-sso",
              checkLoginIframe: true,
              silentCheckSsoRedirectUri: AccountStore.KEYCLOAK_REDIRECT_URI
                ? AccountStore.KEYCLOAK_REDIRECT_URI
                : window.location.origin + baseUrl + "/silent-check-sso.html",
            })
            .then(async (authenticated) => {
              log(
                "keycloaksso: check-sso:",
                authenticated ? "authenticated" : "not authenticated"
              );
              const isCallback = isKeycloakCallback();
              clearKeycloakCallback();
              log("keycloaksso: isCallback", isCallback);
              if (!authenticated && !noLogin) {
                if (!isCallback) {
                  let redirectUri = AccountStore.KEYCLOAK_REDIRECT_URI;
                  if (!redirectUri) redirectUri = window.location.origin + baseUrl;
                  if (authSignal?.initURL && authSignal.initURL !== '/') {
                    redirectUri = window.location.origin + baseUrl + authSignal.initURL;
                    redirectUri = this.patchRedirectUri(redirectUri);
                    log('keycloaksso: redirectUri',redirectUri);
                  }
                  this.keycloak.login({redirectUri});
                } else {
                  reject(Error("Login cancelled!"));
                  keycloakInitializedSignal.value = true;
                  keycloakInitializedSignal.prcessing = false;
                }
              } else {
                try {
                  resolve(this.keycloak);
                  this.startCheckSso();
                } catch (error) {
                  log("link-user: failed", error);
                  reject(error);
                }
                keycloakInitializedSignal.value = true;
                keycloakInitializedSignal.prcessing = false;
              }
            })
          .catch(function (error) {
            log("check-sso: failed", error);
            reject(error);
          });
        }
      });
    }
  };

  getCheckSsoInterval() {
    const interval = AccountStore.KEYCLOAK_SESSION_CHECK_INTERVAL === undefined ? DEFAULT_KEYCLOAK_CHECK_INTERVAL : AccountStore.KEYCLOAK_SESSION_CHECK_INTERVAL;
    return interval * 1000;
  }

  async checkSso() {
     try {
      const username = await auth.getKeycloakLoginUser();
      const authUser = authSignal.authUser;
      const authUserObj = toAuthUserObj(authUser);
      console.log("username/authUserObj.username", username, authUserObj.username);
      if (username !== authUserObj.username) {
        console.log("@@@session invalid reload page...");
        doLoadSession('keycloak');
      }
    } catch {
      console.log("@@@exception for calling keycloak to get user information...");
    }
  }

  async startCheckSso() {
    const interval = this.getCheckSsoInterval();
    if (authSignal.authUser && getLocalStorageItem("IS_KEYCLOAK_SESSION") === "Y") {
      console.log("startCheckSso()", interval);
      setInterval(this.checkSso, interval)
      setTimeout(this.checkSso, DEFAULT_KEYCLOAK_CHECK_INITIAL_DELAY)
    }
  }

  async logout() {
    const keycloak = await this.getKeycloak(true);
    if (keycloak && keycloak.logout) {
      const baseUrl = getBaseUrl();
      await keycloak.logout({
        redirectUri: AccountStore.KEYCLOAK_REDIRECT_URI ? AccountStore.KEYCLOAK_REDIRECT_URI : window.location.origin + baseUrl
      })
    }
  }

}

export const keycloakApi = new KeycloakApi();

const isSamlEnabled = () => {
  const enabled = !!AccountStore.SAML_PATH && !!AccountStore.SAML_SERVICE_PROVIDER && !!AccountStore.SAML_IDENTITY_PROVIDER;
  log('keycloaksso: isSamlEnabled()', enabled)
  return enabled;
};

const isSamlOnly = () => {
  return !isPasswordEnabled() && !isKeycloakEnabled() && isSamlEnabled();
};

const isSamlCallback = () => {
  let isCallback = false;
  const href = window.location.href;
  if (href && href.indexOf("samlState=") !== -1) {
    isCallback = true;
  }
  log('keycloaksso: isSamlCallback()', isCallback);
  return isCallback;
};

const clearSamlCallback = () => {

};

class SamlApi {
  constructor() {
    this.isSamlEnabled = isSamlEnabled.bind(this);
    this.isSamlOnly = isSamlOnly.bind(this);
    this.isSamlCallback = isSamlCallback.bind(this);
    this.clearSamlCallback = clearSamlCallback.bind(this);
  }

  async login() {
    window.location.href = window.location.protocol + '//' + AccountStore.PARSE_DOMAIN + AccountStore.SAML_PATH + '/login';
    await delay(5000);
  }

  async logout() {
    const stateObj = getLocalStorageItemObject("SAML_SESSION");
    if (stateObj) {
      const state = encrypt(stateObj, AccountStore.PARSE_USER_SECRET_KEY)
      window.location.href = window.location.protocol + '//' + AccountStore.PARSE_DOMAIN + AccountStore.SAML_PATH + '/logout?samlState=' + state;
      await delay(5000);
    }
  }

  getCheckSsoInterval() {
    const interval = AccountStore.SAML_SESSION_CHECK_INTERVAL === undefined ? DEFAULT_SAML_CHECK_INTERVAL : AccountStore.SAML_SESSION_CHECK_INTERVAL;
    return interval * 1000;
  }

  async checkSso() {
    window.location.href = window.location.protocol + '//' + AccountStore.PARSE_DOMAIN + AccountStore.SAML_PATH + '/login?isCheckSso=true';
    await delay(5000);
  }


  startCheckSso() {
    const interval = this.getCheckSsoInterval();
    const state = urlparam("samlState", true);
    if (state) {
      const stateObj = decrypt(state, AccountStore.PARSE_USER_SECRET_KEY);
      if (stateObj) {
        const authUser = authSignal.authUser;
        const authUserObj = toAuthUserObj(authUser);
        console.log("username/authUserObj.username", stateObj.username, authUserObj.username);
        if (stateObj.username === authUserObj.username) {
          setLocalStorageItem("IS_SAML_SESSION", "Y");
          setLocalStorageItemObject("SAML_SESSION", stateObj);
          setLocalStorageItemObject("SAML_SESSION_TIME", new Date().getTime());
          setTimeout(() =>{
            this.checkSso();
          }, interval);
        } else {
          this.checkSso();
        }
      }
    } else {
      const lastCheckTime = getLocalStorageItemObject("SAML_SESSION_TIME");
      const now = new Date().getTime();
      const time = now - lastCheckTime;
      const delta = interval - time;
      if (delta < 0 && time < 0) {
        this.checkSso();
      } else {
        console.log('check sso in ', (delta / 1000), 'seconds')
        setTimeout(() =>{
          this.checkSso();
        }, delta);
      }
    }
  }

  getAuthInfo() {
    const state = urlparam("samlState", true);
    const stateObj = decrypt(state, AccountStore.PARSE_USER_SECRET_KEY);
    setLocalStorageItem("IS_SAML_SESSION", "Y");
    setLocalStorageItemObject("SAML_SESSION", stateObj);
    setLocalStorageItemObject("SAML_SESSION_TIME", new Date().getTime());
    const interval = this.getCheckSsoInterval();
    setTimeout(() =>{
      this.checkSso();
    }, interval);
    return stateObj;
  }
}

export const samlApi = new SamlApi();

class SsoHelper {
  resetAllLoginSessionFlags() {
    removeLocalStorageItem('IS_KEYCLOAK_SESSION');
    removeLocalStorageItem('IS_SAML_SESSION');
    removeLocalStorageItem('SAML_SESSION');
  }

  setKeycloakSession() {
    setLocalStorageItem('IS_KEYCLOAK_SESSION', "Y");
  }

  isKeycloakSession() {
    return getLocalStorageItem('IS_KEYCLOAK_SESSION') === "Y";
  }

  isSamlSession() {
    return getLocalStorageItem('IS_SAML_SESSION') === "Y";
  }
}

export const ssoHelper = new SsoHelper();

authSignal.waitFor('authUser').then(() => {
  if (ssoHelper.isKeycloakSession()) {
    keycloakApi.getKeycloak();
  }
  if (ssoHelper.isSamlSession()) {
    samlApi.startCheckSso();
  }
}).catch(err => {
  // ignore not logged in.
})
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { useEditor, useNode } from "@craftjs/core";
import {
  Alert,
  Button,
  Col,
  Comment,
  InputNumber,
  List,
  message,
  Popover,
  Space,
  Switch,
  Typography,
  Upload,
} from "antd";
import { Form, Input, Select, UserAvatar } from "../../../../components/Form";
import { push } from "connected-react-router";
import { AccountStore } from "../../../../constants/Account";
import { getMessage } from "lngProvider";
import moment from "moment";
import { system, comment, dataExplorer } from "../../../../parse-api";
import { handleUploadFile } from "../../../../parse-api/file";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { BiCommentDetail } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { getArraysIntersection, isLoggedIn, log, toAuthUserObj, uid } from "../../../../util/algorithm";
import IntlMessages from "../../../../util/IntlMessages";
import { MyCKEditor, MyMarkdown, MyQuillEditor, SystemSelect } from "../../FormSetup/components";
import {
  convertStyleStr,
  deleteButton,
  EditorCollector,
  getId,
  NodeCollector,
  registerComponent,
  SpLayoutSetting,
  SpStyleSetting,
} from "./common";
import { BsBell } from "react-icons/bs";
import { BsBellFill } from "react-icons/bs";
import { SfpUserAvatar } from "../../FormSetup/components/SfUserAvatar";
import { useLbl } from "../../../../lngProvider";
import { authSignal, settingsSignal } from "../../../../util/signal";

const SpCommentSetting = () => {
  const [allEmailOptions, setAllEmailOptions] = useState([]);
  const [allSmsOptions, setAllSmsOptions] = useState([]);
  const mounted = useRef();

  useEffect(() => {
    mounted.current = true;
    const fetchData = async () => {
      const emailPolicies = await dataExplorer.searchAll(
        "SystemEmailPolicy",
        null,
        "emailName"
      );
      setAllEmailOptions(
        emailPolicies
          .filter((p) => {
            if (p.dataTypes) {
              if (
                getArraysIntersection(p.dataTypes, ["newcomment"]).length > 0
              ) {
                return true;
              }
            }
            return false;
          })
          .map((p) => ({ label: p.emailName, value: p.emailKey }))
      );
      const smsPolicies = await dataExplorer.searchAll(
        "SystemSmsPolicy",
        null,
        "smsName"
      );
      setAllSmsOptions(
        smsPolicies
          .filter((p) => {
            if (p.dataTypes) {
              if (
                getArraysIntersection(p.dataTypes, ["newcomment"]).length > 0
              ) {
                return true;
              }
            }
            return false;
          })
          .map((p) => ({ label: p.smsName, value: p.smsKey }))
      );
    };
    fetchData();
    return () => {
      mounted.current = false;
    };
  }, []);
  return (
    <>
      <Form.Item name="title" label="title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="bordered" label="bordered" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="editor" label="editor">
        <Select className="item-property">
          <Select.Option value="textarea">textarea</Select.Option>
          <Select.Option value="markdown">markdown</Select.Option>
          <Select.Option value="richtext">richtext</Select.Option>
          <Select.Option value="quill">quill</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="imageBase64"
        label="image base64"
        valuePropName="checked"
      >
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="highlight" label="highlight" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="attachment" label="attachment" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="maxCount" label="max count">
        <InputNumber min="1" className="item-property" />
      </Form.Item>
      <Form.Item name="accept" label="accept">
        <Space>
          <Form.Item name="accept" label="accept" noStyle={true}>
            <Input className="item-property" />
          </Form.Item>
          <Typography.Link
            target="_blank" rel="noopener noreferrer"
            href="https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#accept">Need Help?</Typography.Link>
        </Space>
      </Form.Item>
      <Form.Item name="acceptFileTypes" label="file types">
        <Select className="item-property" mode="tags"/>
      </Form.Item>
      <Form.Item name="acceptFileExtensions" label="file exts">
        <Select className="item-property" mode="tags"/>
      </Form.Item>
      <Form.Item name="defaultSharing" label="default sharing">
        <Select className="item-property">
          <Select.Option value="public-image-protected-file">
            public-image-protected-file
          </Select.Option>
          <Select.Option value="public">public</Select.Option>
          <Select.Option value="protected">protected</Select.Option>
          <Select.Option value="private">private</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="approvalRequired"
        label="approval"
        valuePropName="checked"
      >
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="approvers" label="approvers">
        <SystemSelect
          className="admins"
          selectkey="User"
          isUseCode={true}
          mode="multiple"
        />
      </Form.Item>
      <Form.Item name="emailAlert" label="email alert">
        <Select
          className="item-property"
          options={allEmailOptions}
          allowClear
          showSearch
        />
      </Form.Item>
      <Form.Item name="smsAlert" label="sms alert">
        <Select
          className="item-property"
          options={allSmsOptions}
          allowClear
          showSearch
        />
      </Form.Item>
      <Form.Item name="allowSubscribe" label="watch" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="editorStyleStr" label="editor style">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="pageSize" label="page size">
        <InputNumber className="item-property" min={3} max={20} />
      </Form.Item>
      <SpStyleSetting />
      <SpLayoutSetting />
    </>
  );
};

export const SpComment = ({ ...props }) => {
  const {
    connectors: { connect, drag },
    selected,
    style,
  } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions);
  return (
    <SppComment
      doRef={(ref) => connect(drag(ref))}
      selectedStyle={style}
      dbtn={dbtn}
      {...props}
    ></SppComment>
  );
};

const rowKey = (record) => {
  if (record.rowKey) return record.rowKey;
  log("row has no id", record);
  return null;
};

const CommentList = ({
  approvalRequired,
  comments,
  textarea,
  richtext,
  markdown,
  quill,
  reply,
  isApprover,
  update,
  userId,
  commentFlow,
  subscriptionButton,
  ...otherProps
}) => {
  const dispatch = useDispatch();
  const locale = settingsSignal.locale;
  const lbl = useLbl(locale);
  const onDownload = (file) => {
    if (file.url && file.url.startsWith(AccountStore.PARSE_HOST_URL + "/files/")) {
      dispatch(
        push(file.url.replace(AccountStore.PARSE_HOST_URL + "/files/", "/parse/files/"))
      );
    } else {
      log("file is not host in this server", file);
    }
  };
  const prepareContent = (props, approvalRequired) => {
    const fileList = props.fileList;
    const displayContent = <>
      {markdown && <MyMarkdown readOnly={true} value={props.content} />}
      {richtext && <MyCKEditor readOnly={true} value={props.content} />}
      {quill && <MyQuillEditor readOnly={true} value={props.content} />}
      {textarea && <p>{props.content}</p>}
    </>
    return (
      <>
        {props.shielded &&
          <p>
            <Alert type="warning"
            description={
            <>
              <IntlMessages id={"system.page.comment.status." + props.status} text={props.remarks}/>
              {props.content && <p>&nbsp;</p>}
              {displayContent}
              {fileList && fileList.length > 0 && (
                <Upload
                  fileList={fileList}
                  showUploadList={{ showDownloadIcon: true, showRemoveIcon: false }}
                  onDownload={onDownload}
                />
              )}
            </>}/>
          </p>
        }
        {!props.shielded &&
          <p>
            {displayContent}
            {fileList && fileList.length > 0 && (
              <Upload
                fileList={fileList}
                showUploadList={{ showDownloadIcon: true, showRemoveIcon: false }}
                onDownload={onDownload}
              />
            )}
          </p>
        }
      </>
    );
  };
  return (
    <List
      dataSource={comments}
      rowKey={rowKey}
      {...otherProps}
      header={
        <div>
          {subscriptionButton}
          {`${comments.length} ${comments.length > 1 ? lbl("system.page.comment.lbl.replies", "replies") : lbl("system.page.comment.lbl.reply", "reply") }`}
        </div>
      }
      itemLayout="horizontal"
      renderItem={(props) => {
        const isAuthor = userId === props.author?.objectId;
        const flowActions = commentFlow?.[props.status]?.actions
          ?.filter(a => (isAuthor && a.isAuthor) || (a.isApprover && isApprover))
          .map(a =>
            <span key={a.action} onClick={() => update(props, {action:a.action})}>
                <IntlMessages id={`system.page.comment.action.${a.action}`} text={a.title} />
            </span>
          ) || [];
        return (
          <Comment
            author={props.author?.nickname}
            avatar={<UserAvatar userObj={props.author} from="COMM_LIST" />}
            datetime={props.datetime?.calendar()}
            actions={[
              !props.shielded && <span onClick={() => reply(props)}>
                <IntlMessages id="system.page.comment.reply" text="Reply" />
              </span>,
              ...flowActions
            ]}
            content={prepareContent(props, approvalRequired)}
          ></Comment>
        );
      }}
    />
  );
};

const getUploadButton = (listType) => {
  if (listType === "picture-card") {
    return (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>
          <IntlMessages id="system.page.library.upload" text="Upload" />
        </div>
      </div>
    );
  } else {
    return (
      <Button icon={<UploadOutlined />}>
        <IntlMessages id="system.page.library.upload" text="Upload" />
      </Button>
    );
  }
};

const Editor = ({ editorRef, style, editorStyle,
  onChange, onSubmit, submitting, value, textarea, richtext, markdown, quill,
  attachment, maxCount, imageBase64, highlight, accept,
  beforeUpload, onRemoveAttachment, uploading, fileList, editing, isDesignMode }) => {
  return (
    <>
      <Form.Item>
        {markdown &&
          <MyMarkdown editorRef={editorRef} onChange={onChange} value={value} minHeight={100} />
        }
        {richtext &&
          <MyCKEditor editorRef={editorRef} editorStyle={editorStyle} onChange={onChange} value={value} imageBase64={imageBase64} highlight={highlight}/>
        }
        {quill &&
          <MyQuillEditor editorRef={editorRef} editorStyle={editorStyle} onChange={onChange} value={value} imageBase64={imageBase64} highlight={highlight}/>
        }
        {textarea &&
          <Input.TextArea ref={editorRef} autoSize={true} onChange={(e) => onChange(e.target.value)} value={value} />
        }
      </Form.Item>
      {attachment && <Form.Item>
        <Upload
          accept={accept}
          beforeUpload={beforeUpload}
          onRemove={onRemoveAttachment}
          fileList={fileList}
          maxCount={maxCount}>
          {getUploadButton()}
        </Upload>
      </Form.Item>}
      <Form.Item>
        <Button loading={submitting} disabled={uploading || !editing || isDesignMode} onClick={onSubmit} className="add-comment">
          <IntlMessages id="system.form.comment.add-comment" text="Add Comment" />
        </Button>
      </Form.Item>
    </>
  )
};

const MyComment = ({
  dataKey,
  className,
  itemKey,
  isDesignMode,
  commentKey,
  textarea,
  richtext,
  markdown,
  quill,
  attachment,
  maxCount,
  imageBase64,
  highlight,
  accept,
  acceptFileTypes,
  acceptFileExtensions,
  defaultSharing,
  demo,
  style,
  editorStyle,
  approvalRequired,
  approvers,
  admins,
  adminRoles,
  emailAlert,
  smsAlert,
  link,
  bell, setBell, subscribers, setSubscribers, subscriptionButton,
  ...otherProps
}) => {
  const { locale } = settingsSignal;
  const [systemComment, setSystemComment] = useState();
  const [comments, setComments] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState(null);
  const [localFileList, setLocalFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [commentFlow, setCommentFlow] = useState();
  const [api, contextHolder] = message.useMessage();
  const editorRef = useRef();
  const { authUser } = authSignal;
  const authUserObj = toAuthUserObj(authUser);
  const isApprover = useMemo(() => {
    return !!(getArraysIntersection(approvers, [authUserObj.username]).length > 0 ||
      getArraysIntersection(admins, [authUserObj.username]).length > 0 ||
      getArraysIntersection(adminRoles, authUserObj.roles).length > 0);
  }, [approvers, admins, adminRoles, authUserObj])

  const replyText = getMessage(
    locale.languageId,
    "system.page.comment.action.reply",
    "Reply"
  );
  const mounted = useRef();
  const subject = useRef();
  const subjectKey = useRef();

  useEffect(() => {
    mounted.current = true;
    const fetchData = async () => {
      const f = await comment.getSystemCommentFlow();
      setCommentFlow(f);
    }
    fetchData();
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (localFileList.length === 0 && (!value || value === '<p><br></p>')) {
      if (editing) {
        log('isEditing', false);
        setEditing(false);
      }
    } else {
      if (!editing) {
        log('isEditing', true);
        setEditing(true);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, localFileList]);

  const onRemoveAttachment = async (file) => {
    const index = localFileList.indexOf(file);
    const newFileList = localFileList.slice();
    newFileList.splice(index, 1);
    if (mounted.current) setLocalFileList(newFileList);
  };

  const beforeUpload = async (file) => {
    const fileList = [...localFileList];
    let newFileList = null;
    if (maxCount) {
      if (maxCount > 1) {
        if (fileList.length >= maxCount) {
          api.error(
            <IntlMessages
              id="system.page.upload_limit"
              text="Reached max file count ({maxCount})"
              values={{ maxCount: maxCount }}
            />
          );
        } else {
          newFileList = [...fileList, file];
        }
      } else if (maxCount === 1) {
        newFileList = [file];
      }
    } else {
      newFileList = [...fileList, file];
    }

    if (newFileList) {
      if (mounted.current) setLocalFileList(newFileList);
    }

    return false;
  };

  useEffect(() => {
    localFileList.forEach((f) => {
      if (!f.status) {
        f.permission = defaultSharing;
        if (mounted.current) setUploading(true);
        handleUploadFile(
          f.name,
          f,
          (file, progressValue, info, parseFile, error) => {
            let changed = false;
            let completed = false;
            if (info) {
              const { type } = info;
              if (type === "upload" && progressValue !== null) {
                file.percent = progressValue * 100;
                file.status = "uploading";
                changed = true;
              }
              if (type === "download") {
                file.status = "done";
                changed = true;
              }
            } else if (parseFile) {
              const index = localFileList.indexOf(file);
              const newFile = { ...file };
              newFile.url = parseFile.url();
              newFile.fileKey = parseFile.name();
              newFile.name = file.name;
              newFile.originFileObj = undefined;
              localFileList[index] = newFile;
              changed = true;
              completed = true;
            } else if (error) {
              if (React.isValidElement(error)) {
                api.error(error);
              } else {
                api.error(`${error}`);
              }
              file.status = "error";
              changed = true;
              completed = true;
            }
            if (changed) {
              const newFileList = [...localFileList];
              if (mounted.current) setLocalFileList(newFileList);
              if (completed) {
                if (mounted.current) setUploading(false);
              }
            }
          }, acceptFileTypes, acceptFileExtensions
        );
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localFileList]);

  useEffect(() => {
    const fetchData = async () => {
      if (className && dataKey && itemKey && commentKey) {
        const systemComment = await comment.getSystemComment(
          className,
          itemKey,
          dataKey,
          commentKey
        );
        setSystemComment(systemComment);
        setComments(systemComment.comments ? systemComment.comments : []);
      }
    };
    fetchData();
    const handleNotification = async () => {
      const newSubject = `${className}_${itemKey}_${dataKey}`;
      log('subject', newSubject);
      if (className && itemKey && dataKey && commentKey) {
        if (subject.current !== newSubject) {
          if (subject.current) {
            await system.unsubscribe(subject.current, subjectKey.current);
            subject.current = null;
          }
          subjectKey.current = await system.subscribe(newSubject, (notifyId) => {
            log("fetch data...", notifyId);
            fetchData();
            system.received(notifyId);
          })
          subject.current = newSubject;
        }
      }
    }
    requestAnimationFrame(handleNotification);
  }, [dataKey, itemKey, className, commentKey]);

  useEffect(() => {
    doSyncBellValue();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bell]);

  useEffect(() => {
    if (setSubscribers) setSubscribers(systemComment?.subscribers ? systemComment.subscribers.sort() : []);
  }, [setSubscribers, systemComment])

  const doSyncBellValue = async () => {
    if (systemComment && isLoggedIn(authUserObj)) {
      let subscribed = false;
      if (systemComment.subscribers) {
        subscribed = systemComment.subscribers.indexOf(authUserObj.username) !== -1;
      }
      if (subscribed !== bell) {
        const newSystemComment = await comment.subscribeSystemComment(className, itemKey, dataKey, commentKey, !!bell);
        log("comments", newSystemComment);
        setSystemComment(newSystemComment);
      }
    }
  }

  const handleSubmit = async () => {
    try {
      if (!value) {
        return;
      }
      if (mounted) setSubmitting(true);
      if (demo) {
        await new Promise((r) => setTimeout(r, 1000));
        if (mounted)
          setComments([
            ...comments,
            {
              rowKey: uid(),
              author: authUserObj,
              content: value,
              richtext: richtext,
              markdown: markdown,
              fileList: localFileList.filter((f) => f.status === "done"),
              datetime: moment(),
            },
          ]);
      } else if (className && dataKey && itemKey) {
        const newComment = {
          content: value,
          richtext: richtext || quill,
          markdown: markdown,
          fileList: localFileList.filter((f) => f.status === "done"),
          datetime: moment(),
        };
        const newSystemComment = await comment.addSystemComment(
          className,
          itemKey,
          dataKey,
          commentKey,
          newComment,
          emailAlert,
          smsAlert,
          link
        );
        const newSubject = `${className}_${itemKey}_${dataKey}`;
        system.notify(newSubject);
        setComments(newSystemComment.comments ? newSystemComment.comments : []);
      } else {
        api.error(
          <IntlMessages
            id="system.page.comment.missing-parameter"
            text="missing parameter"
          />
        );
      }
      if (mounted) setValue(null);
      if (mounted) setSubmitting(false);
      if (mounted) setLocalFileList([]);
    } catch (e) {
      console.error("fail to save system comment", e);
      api.error(`${e}`);
    }
  };

  const update = async (props, updates) => {
    const newSystemComment = await comment.updateSystemComment(
      className,
      itemKey,
      dataKey,
      commentKey,
      props.objectId,
      updates,
      emailAlert,
      smsAlert,
      link
    );
    setComments(newSystemComment.comments ? newSystemComment.comments : []);
    const newSubject = `${className}_${itemKey}_${dataKey}`;
    system.notify(newSubject);
  }

  const handleChange = (value) => {
    setValue(value);
  };

  const reply = (props) => {
    let val = props.content;
    if (val) {
      const datetime = props.datetime;
      const replyHeader =
        (replyText ? replyText : "Reply") + " " +
        props.author.nickname + " " +
        datetime.format('MMMM Do YYYY, h:mm:ss a');
      if (richtext || quill) {
        const newVal = "<p>"+replyHeader+"</p><blockquote>"+val+"</blockquote><p>&nbsp;</p>";
        val = newVal;
      } else {
        const lines = val.split("\n")
        const qouted = [];
        qouted.push(replyHeader);
        lines.forEach(l => {
          qouted.push(">" + l);
        })
        qouted.push("");
        const newVal = qouted.join("\n");
        val = newVal;
      }
    }
    setValue(val);
    if (editorRef.current?.focus) {
      editorRef.current.focus({ cursor: "end" });
    }
  }

  if (demo || (dataKey && className)) {
    return (
      <>
        {contextHolder}
        {comments.length > 0 && (
          <CommentList
            comments={comments}
            richtext={richtext}
            markdown={markdown}
            textarea={textarea}
            quill={quill}
            reply={reply}
            isApprover={isApprover}
            update={update}
            userId={authUserObj.uid}
            approvalRequired={approvalRequired}
            commentFlow={commentFlow}
            subscriptionButton={subscriptionButton}
            {...otherProps}
          />
        )}
        {((commentKey && isLoggedIn(authUserObj)) || isDesignMode) && <Comment
          avatar={<UserAvatar userObj={authUserObj} from="COMM_EDIT" />}
          content={
            <Editor
              editorRef={editorRef}
              onChange={handleChange}
              onSubmit={handleSubmit}
              submitting={submitting}
              textarea={textarea}
              richtext={richtext}
              markdown={markdown}
              quill={quill}
              editorStyle={editorStyle}
              value={value}
              attachment={attachment}
              maxCount={maxCount}
              imageBase64={imageBase64}
              highlight={highlight}
              onRemoveAttachment={onRemoveAttachment}
              fileList={localFileList}
              beforeUpload={beforeUpload}
              uploading={uploading}
              editing={editing}
              isDesignMode={isDesignMode}
              accept={accept}
            />
          }
        />}
        {!commentKey && !isDesignMode && <Alert type="warning"
          description={<IntlMessages id="system.page.comment.login-first" text="Please login to add comment."/>}/>}
      </>
    );
  } else {
    return null;
  }
};

export const SppComment = ({
  doRef,
  dbtn,
  selectedStyle,
  itemKey,
  pageKey,
  pageState,
  setPageState,
  accept,
  acceptFileTypes,
  acceptFileExtensions,
  defaultSharing,
  bordered,
  pageSize,
  editor,
  editorStyleStr,
  attachment,
  maxCount,
  imageBase64,
  highlight,
  approvalRequired,
  approvers,
  emailAlert,
  smsAlert,
  allowSubscribe,
  ...otherProps
}) => {
  const [commentKey, setCommentKey] = useState();
  const [dataKey, setDataKey] = useState();
  const [bell, setBell] = useState(false);
  const [subscribers, setSubscribers] = useState([]);

  let style = otherProps.style || {};
  style = { ...style, ...selectedStyle };
  const props = { ...otherProps, style };
  const editorStyle = convertStyleStr(editorStyleStr);
  const pagination = pageSize ? { pageSize: pageSize } : null;
  const richtext = editor === "richtext";
  const markdown = editor === "markdown";
  const quill = editor === "quill";
  const textarea = editor === "textarea";
  const timer = useRef();

  const content = (
    <Space direction="horizontal">
      {subscribers.map(s => {
        return <SfpUserAvatar key={s} username={s} size="small" />
      })}
    </Space>
  );

  const subscriptionButton = allowSubscribe && <span className="page-comment-subscribe-button">
    <Popover title={<IntlMessages id="system.form.comment.subscribers" text="Subscribers"/>} content={content} placement="bottomRight">
      <Button size="small" shape="circle" type={"text"} className={bell ? 'subscribed' : 'non-subscribed'} icon={bell ? <BsBellFill/> : <BsBell/>} onClick={e => setBell(!bell)}/>
    </Popover>
  </span>

  useEffect(() => {
    const dataKey = pageState?.pageKey + (pageState?.pageIsPreview ? '.preview' : '');
    setDataKey(dataKey);
  }, [pageState]);

  useEffect(() => {
    const fetchData = async () => {
      if (dataKey && itemKey) {
        const dataClass = "SystemPage";
        const accessKey = await dataExplorer.getAccessKey(
          dataClass,
          null,
          dataKey,
          itemKey
        );
        setCommentKey(accessKey);
      }
    };
    if (timer.current) {
      clearInterval(timer.current);
      timer.current = null;
    }
    fetchData();
    timer.current = setInterval(fetchData, 60 * 5 * 1000)
    return () => {
      if (timer.current) {
        clearInterval(timer.current);
        timer.current = null;
      }
    }
  }, [dataKey, itemKey]);
  return (
    <Col ref={doRef} {...props}>
      <MyComment
        dataKey={dataKey}
        itemKey={itemKey}
        className={"SystemPage"}
        commentKey={commentKey}
        isDesignMode={!!doRef}
        bordered={bordered}
        textarea={textarea}
        richtext={richtext}
        markdown={markdown}
        quill={quill}
        editorStyle={editorStyle}
        pagination={pagination}
        demo={doRef != null}
        attachment={attachment}
        maxCount={maxCount}
        imageBase64={imageBase64}
        highlight={highlight}
        defaultSharing={defaultSharing}
        approvalRequired={approvalRequired}
        approvers={approvers}
        admins={pageState?.pageAdmins || []}
        adminRoles={pageState?.pageAdminRoles || []}
        emailAlert={emailAlert}
        smsAlert={smsAlert}
        link={window.location.href}
        bell={bell} setBell={setBell}
        subscribers={subscribers} setSubscribers={setSubscribers}
        subscriptionButton={subscriptionButton}
        accept={accept}
        acceptedFileTypes={acceptFileTypes}
        accepteFileExtensions={acceptFileExtensions}
      />
      {dbtn}
    </Col>
  );
};
SppComment.enablePageState = true;

SpComment.craft = {
  displayName: "Comment",
  rules: {
    canMoveIn: function (incomingNode, currentNode) {
      return false;
    },
  },
  related: {
    settings: SpCommentSetting,
  },
};

SpComment.validate = (props, { parents, container, extraParams }) => {
  if (!extraParams.comments) extraParams.comments = {};
  extraParams.comments[props.itemKey] = {
    approvers: props.approvers,
    approvalRequired: props.approvalRequired,
  };
};

registerComponent({
  key: "SpComment",
  component: SpComment,
  runtimeComponent: SppComment,
  template: (
    <SpComment
      itemKey={getId("comment")}
      className="sp-panel sp-comment"
      title={"Comment"}
      editor="textarea"
      editorStyleStr={
        "width:100%,minHeight:100px,borderWidth:1,borderStyle:solid,borderColor:lightgray"
      }
      defaultSharing={"public-image-protected-file"}
      span="24"
    />
  ),
  title: <IntlMessages id="system.page.library.comment" text="Comment" />,
  icon: <BiCommentDetail className="react-icons icon-bi" />,
  type: "Component",
  sequence: 11,
});

export default SpComment;
